<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-account-multiple"></i>
                Executives
            </h1>

            <p class="card-description">List of {{ level }} executives</p>

            <div class="row">
                <div class="col-12">
                    <div id="exectives-listing-wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-xs-12 col-2" v-if="level != 'all'">
                                <button type="button" class="btn btn-primary" @click="addExecutive">
                                    <i class="mdi mdi-plus"></i>
                                    Add {{ level | capitalize }} Executive
                                </button>
                            </div>

                            <div class="col-xs-12" :class="(level == 'all') ? 'col-7' : 'col-6'">
                                <div class="row">
                                    <div class="col-xs-12 col-4">
                                        <div class="form-group">
                                            <select
                                                id="constituency"
                                                ref="constituency"
                                                class="select-2 form-control"
                                                data-placeholder="Select A Constituency">
                                                    <option value="">Select A Constituency</option>

                                                    <option v-for="(constituency, index) in constituencies"
                                                        :value="constituency.id"
                                                        :key="index">{{ constituency.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-4">
                                        <div class="form-group">
                                            <select
                                                id="branch"
                                                ref="branch"
                                                class="select-2 form-control"
                                                data-placeholder="Select A Branch">
                                                    <option value="">Select A Branch</option>
                                                    <option v-for="(branch, index) in branches"
                                                        :value="branch.id"
                                                        :key="index">{{ branch.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-4">
                                        <div class="form-group">
                                            <select
                                                id="type"
                                                ref="type"
                                                class="select-2 form-control"
                                                data-placeholder="Select A Position">
                                                    <option value="">Select A Position</option>
                                                    <option v-for="(type, index) in executiveTypes"
                                                        :value="type.id"
                                                        :key="index">{{ getFullPosition(type) }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12" :class="(level == 'all') ? 'col-5' : 'col-4'">
                                <toolbar
                                    @show="val => params.per_page = val"
                                    @search="val => params.keyword = val" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <template v-if="executives.length">
                                    <div class="table-responsive">
                                        <executives-table
                                            @edit="editExecutive"
                                            :executives="executives"
                                            :level="level"
                                            @view="viewExecutive"
                                            @delete="deleteExecutive" />
                                    </div>

                                    <app-pagination
                                        v-if="pageDetails && pageDetails.total"
                                        :pageDetails="pageDetails"
                                        @navigate="getExecutives($event)" />
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">There are no such executives in the system.</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal
            :show="showModal"
            :executive="modalExecutive"
            :level="level"
            @save-executive="saveExecutive"
            @update:show="val => showModal = val"></modal>

        <view-modal
            :show="showViewModal"
            :executive="modalExecutive"
            @update:show="val => showViewModal = val"></view-modal>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import Modal from './Modal';
import ViewModal from './ViewModal';
import ExecutivesTable from './Table';

export default {
    components: {
        Modal,
        ViewModal,
        ExecutivesTable
    },

    props: ['level'],

    computed: {
        ...mapGetters({
            branches: 'Branches/getAll',
            executives: 'Executives/getAll',
            constituencies: 'Constituencies/getAll',
            pageDetails: 'Executives/getPageDetails',
            executiveTypes: 'ExecutiveTypes/getAll'
        })
    },

    data() {
        return {
            showModal: false,
            showViewModal: false,
            modalExecutive: {},
            params: {
                paginate: true,
                per_page: null,
                keyword: null,
                constituency_id: null,
                branch_id: null,
                executive_type_id: null
            },

            isFiltered: false
        };
    },

    watch: {
        params: {
            deep: true,
            handler() {
                if (!this.params.constituency_id) {
                    $(this.$refs.branch).val("").change();
                }

                if (this.isFiltered) {
                    this.getExecutives();
                }
            }
        },

        level() {
            this.resetParams();
            this.getExecutiveTypes();
            this.getExecutives();
        }
    },

    methods: {
        ...mapActions({
            load: 'Executives/all',
            store: 'Executives/store',
            update: 'Executives/update',
            delete: 'Executives/delete',
            loadConstituencies: 'Constituencies/all',
            loadBranches: 'Branches/all',
            loadExecutiveTypes: 'ExecutiveTypes/all'
        }),

        /**
         * Open modal for adding an executive
         *
         * @return {Undefined}
         */
        addExecutive() {
            this.showModal = true;
        },

        /**
         * Open the modal for editing executives
         *
         * @param {Object} executive Executive to be updated
         * @return {Undefined}
         */
        editExecutive(executive) {
            this.showModal = true;
            this.modalExecutive = { ...executive };
        },

        /**
         * Pop up a modal to display an executive's details
         *
         * @param {Object} executive Executive to be viewed
         * @return {Undefined}
         */
        viewExecutive(executive) {
            this.showViewModal = true;
            this.modalExecutive = { ...executive };
        },

        /**
         * Get executives from server
         *
         * @param {Object} query
         * @return {Undefined}
         */
        getExecutives(query = {}) {
            for (let param of Object.keys(this.params)) {
                if (this.params[param]) {
                    query[param] = this.params[param];
                }
            }

            let level = (this.level == 'regional') ? 'region' : this.level;

            if (level != 'all') {
                query.level = level;
            }

            if (query.keyword) {
                delete query.page;
            }

            this.load(query)
                .then(() => {
                    if (query.filtered) {
                        this.isFiltered = true;
                    }
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Get constituencies into state
         *
         * @return {Undefined}
         */
        getConstituencies() {
            return this.loadConstituencies({})
                .then(() => {
                    if (this.$route.query.constituency_id) {
                        $(this.$refs.constituency).val(this.$route.query.constituency_id).change();
                    }

                    return Promise.resolve();
                })
                .catch(errors =>  {
                    this.notify(this.buildErrors(errors), 'error');

                    return Promise.reject();
                });
        },

        /**
         * Add an executive
         *
         * @param {Object} executive Executive to be saved
         * @return {Undefined}
         */
        saveExecutive(executive) {
            let keyword = 'added';
            let savingMethod = this.store;
            let payload = { data: new FormData() };

            if (executive.id !== 'undefined' && executive.id) {
                keyword = 'updated';
                payload.id = executive.id;
                savingMethod = this.update;
            }

            for (let key of Object.keys(executive)) {
                payload.data.append(key, executive[key]);
            }

            savingMethod(payload)
                .then(response => {
                    this.notify(`The executive was ${keyword} successfully.`);
                    this.getExecutives();
                    this.showModal = false;
                    this.modalCandidate = {};
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Delete the specified executive
         *
         * @param {Number} id
         * @return {Undefined}
         */
        deleteExecutive(id) {
            Swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this executive and all associated data!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes - Delete it.',
                confirmButtonClass: 'bg-danger',
                cancelButtonText: 'No - Cancel Delete'
            })
            .then(response => {
                if (response.value) {
                    this.delete(id)
                        .then(() => {
                            this.notify('The executive was deleted successfully.');
                            this.getExecutives();
                        })
                        .catch(errors => this.notify(this.buildErrors(errors), 'error'))
                }
            });
        },

        /**
         * Set branch value when the select changes.
         *
         * @param {Object} event
         * @return {Undefined}
         */
        setBranch(event) {
            this.params.branch_id = event.target.value;
        },

        /**
         * Set user value when the select changes.
         *
         * @param {Object} event
         * @return {Undefined}
         */
        setConstituency(event) {
            this.params.constituency_id = event.target.value;
            this.getBranches(event);

            this.$nextTick(() => {
                $(this.$refs.branch).change();
            });
        },

        /**
         * Load branches
         *
         * @param {Object} event
         * @return {Undefined}
         */
        getBranches(event) {
            const constituency_id = event.target.value;

            if (!constituency_id) {
                return false;
            }

            this.loadBranches({
                    constituency_id,
                    hide_unknown: true,
                })
                .then(() => {
                    if (this.$route.query.branch_id) {
                        $(this.$refs.branch).val(this.$route.query.branch_id).change();
                    }
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Load executive types for executive type filter
         *
         * @return {Undefined}
         */
        getExecutiveTypes() {
            let level = this.level == 'regional' ? 'region' : this.level;
            let query = {};

            if (level != 'all') {
                query['level'] = level;
            }

            this.loadExecutiveTypes(query)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Save the id of the selected executive type in the state
         *
         * @param {Object} event
         * @return {Undefined}
         */
        setExecutiveType(event) {
            this.params.executive_type_id = event.target.value;
        },

        /**
         * Register event listeners for select 2.
         *
         * @return {Undefined}
         */
        registerEventListeners() {
            $(this.$refs.branch).on('change', this.setBranch);
            $(this.$refs.constituency).on('change', this.setConstituency);
            $(this.$refs.type).on('change', this.setExecutiveType);
        },

        /**
         * Get the full position of an executive type
         *
         * @param {Object} type Type of executive
         */
        getFullPosition(type) {
            let qualifier = '';

            if (this.level.toLowerCase() === 'all') {
                qualifier = type.hierarchy ? type.hierarchy.name : '';

                if (qualifier == 'Region') {
                    qualifier = 'Regional';
                }
            }

            return `${qualifier} ${type.name}`.trim();
        },

        /**
         * Reset the params
         *
         * @return {Undefined}
         */
        resetParams() {
            for (let key of Object.keys(this.params)) {
                if (key != 'paginate' && key != 'per_page') {
                    this.params[key] = null;
                }
            }

            $(this.$refs.branch).val("").change();
            $(this.$refs.constituency).val("").change();
            $(this.$refs.type).val("").change();
        }
    },

    mounted() {
        const query = {
            filtered: true
        };

        if (this.$route.query.branch_id) {
            query.branch_id = this.$route.query.branch_id;
        }

        if (this.$route.query.constituency_id) {
            query.constituency_id = this.$route.query.constituency_id;
        }

        this.registerEventListeners();
        this.getExecutiveTypes();
        this.getConstituencies()
            .then(() => this.getExecutives(query));
    }
}
</script>