<template>
    <table id="executives-listing" class="table table-striped dataTable no-footer" role="grid">
        <thead>
            <tr role="row">
                <th></th>
                <th class="sorting">Name</th>
                <th class="sorting">Position</th>
                <th class="sorting">Phone</th>
                <th class="sorting">Branch</th>
                <th class="sorting" v-if="level == 'regional'">Region</th>
                <th class="sorting" v-else-if="level == 'constituency' || level == 'all'">Constituency</th>
                <th class="sorting">Added On</th>
                <th style="width: 10%"></th>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(executive, index) in executives" :key="index">
                <td class="py-1"><img :src="photoSrc(executive.photo)"></td>
                <td>{{ executive.name }}</td>
                <td>{{ level == 'all' ? executive.type.hierarchy.name : '' }} {{ executive.type.name }} </td>
                <td>{{ executive.phone  ? executive.phone : 'N/A' }}</td>
                <td>{{ executive.branch ? executive.branch.name : 'N/A' }}</td>
                <td v-if="level == 'regional'">{{ (executive.branch && executive.branch.constituency) ? executive.branch.constituency.region.name : 'N/A' }}</td>
                <td v-else-if="level == 'constituency' || level == 'all'">{{ executive.branch ? executive.branch.constituency.name : 'N/A' }}</td>
                <td>{{ executive.created_at | formattedDateTime }}</td>
                <td>
                    <button class="btn btn-info btn-action mr-3" @click="$emit('view', executive)" data-toggle="popover" data-content="View Executive">
                        <i class="mdi mdi-eye"></i>
                    </button>

                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', executive)" data-toggle="popover" data-content="Edit Executive">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', executive.id)" data-toggle="popover" data-content="Delete Executive">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: ['executives', 'level'],

        methods: {
            photoSrc(photo) {
                return photo ? photo : this.placeholder;
            }
        }
    }
</script>