<template>
     <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="saveExecutive"
        @update:show="$emit('update:show', $event)">
			<div class="row executives-modal-body">
                <div class="col-12">
                    <div class="form-group text-center">
                        <img class="img-thumbnail" :src="photoSrc">

                        <br><br>

                        <input
                            type="file"
                            name="file"
                            accept="image/*"
                            ref="uploadField"
                            @change="uploadImage">

                        <button
                            type="button"
                            class="btn btn-info btn-fill upload-btn"
                            @click="initImageUpload">
                                Change Image
                        </button>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-12">
                            <div class="form-group" :class="getFieldClass('name')">
                                <label for="name">Name</label>
                                <input id="name"
                                    type="text"
                                    name="name"
                                    v-model="payload.name"
                                    class="form-control"
                                    v-validate="rules.name"
                                    placeholder="Full Name"/>

                                <error-label :message="errors.first('name')" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-6">
                            <div class="form-group">
                                <label>Date of Birth</label>

                                <input type="date" name="date_of_birth" class="form-control" v-model="payload.date_of_birth">
                            </div>
                        </div>

                        <div class="col-xs-12 col-6">
                            <div class="form-group">
                                <label>Gender</label>

                                <div class="select2-wrapper">
                                    <select class="select-2"
                                        name="gender">
                                        <option value="">Select gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-6">
                            <div class="form-group" :class="getFieldClass('email')">
                                <label for="email">Email</label>

                                <input id="email"
                                    type="text"
                                    name="email"
                                    v-model="payload.email"
                                    class="form-control"
                                    v-validate="rules.email"
                                    placeholder="Email"/>

                                    <error-label :message="errors.first('email')" />
                            </div>
                        </div>

                        <div class="col-xs-12 col-6">
                            <div class="form-group" :class="getFieldClass('phone')">
                                <label for="phone">Phone</label>

                                <input type="text"
                                    name="phone"
                                    v-model="payload.phone"
                                    class="form-control"
                                    v-validate="rules.phone"
                                    placeholder="Phone"/>

                                <error-label :message="errors.first('phone')" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-6">
                            <div class="form-group">
                                <label>Location</label>

                                <input type="text"
                                    name="location"
                                    class="form-control"
                                    v-model="payload.location"
                                    v-validate="rules.location"
                                    placeholder="Location"/>
                            </div>
                        </div>

                        <div class="col-xs-12 col-6">
                            <div class="form-group">
                                <label>Occupation</label>

                                <input type="text"
                                    name="occupation"
                                    class="form-control"
                                    v-model="payload.occupation"
                                    v-validate="rules.occupation"
                                    placeholder="Occupation"/>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-6">
                            <div class="form-group">
                                <label>Constituency</label>

                                <div class="select2-wrapper">
                                    <select class="select-2 form-control" name="constituency_id">
                                        <option value="">Select a constituency</option>
                                        <option v-for="(constituency, index) in constituencies"
                                            :value="constituency.id"
                                            :key="index">{{ constituency.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-6">
                            <div class="form-group" :class="getFieldClass('branch_id')">
                                <label>Branches</label>

                                <div class="select2-wrapper">
                                    <select class="select-2 form-control"
                                        name="branch_id"
                                        :disabled="!constituencyId"
                                        v-validate="rules.branch_id">
                                        <option value="">Select a branch</option>
                                        <option v-for="(branch, index) in branches"
                                            :value="branch.id"
                                            :key="index">
                                            {{ branch.name }}
                                        </option>
                                    </select>
                                </div>

                                <error-label :message="errors.first('branch_id')" />
                            </div>
                        </div>

                        <div class="col-xs-12 col-6">
                            <div class="form-group" :class="getFieldClass('executive_type_id')">
                                <label>Position</label>

                                <div class="select2-wrapper">
                                    <select class="select-2 form-control" name="executive_type_id">
                                        <option value="">Select a position</option>
                                        <option v-for="(executiveType, index) in executiveTypes"
                                            :value="executiveType.id"
                                            :key="index">
                                            {{ executiveType.name }}
                                        </option>
                                    </select>
                                </div>

                                <error-label :message="errors.first('executive_type_id')" />
                            </div>
                        </div>

                        <div class="col-xs-12 col-6">
                            <div class="form-group">
                                <label>Education</label>

                                <div class="select2-wrapper">
                                    <select class="select-2 form-control" name="educational_level_id">
                                        <option value="">Select a level</option>
                                        <option v-for="(level, index) in educationalLevels"
                                            :value="level.id"
                                            :key="index">
                                            {{ level.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
    </modal>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },

            executive: {
                type: Object,
                default: {}
            },

            level: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                payload: {
                    name: '',
                    branch_id: '',
                    email: '',
                    executive_type_id: '',
                    educational_level_id: '',
                    gender: '',
                    phone: '',
                    email: '',
                    photo: '',
                    date_of_birth: '',
                    occupation: '',
                    location: ''
                },

                rules: {
                    name: 'required|min:2',
                    branch_id: 'required',
                    phone: 'numeric',
                    executive_type_id: 'required'
                },

                previewImage : '',

                constituencyId: ''
            }
        },

        computed: {
            ...mapGetters({
                branches: 'Branches/getAll',
                executiveTypes: 'ExecutiveTypes/getAll',
                constituencies: 'Constituencies/getAll',
                educationalLevels: 'EducationalLevels/getAll'
            }),

            /**
             * Get the photo source.
             * When there is no photo display placeholder.
             *
             * @return {String}
             */
            photoSrc() {
                if (this.payload.photo) {
                    return this.previewImage ? this.previewImage : this.payload.photo;
                }

                return this.placeholder;
            },

             /**
             * Check if the current operation is an update.
             *
             * @return {Boolean}
             */
            isUpdate() {
                return Object.keys(this.executive).length > 0;
            },

            /**
             * Title for the modal depending on the current operation.
             *
             * @return {String}
             */
            title() {
                let type = this.level.charAt(0).toUpperCase() + this.level.slice(1);
                let action = this.isUpdate ? 'Edit' : 'Add';

                return `${action} ${type} Executive`;
            }
        },

        watch: {
            /**
             * Toggle the id property depending on the save operation. Update or Save.
             *
             * @param {Boolean} value
             * @return {Undefined}
             */
			show(value) {
                this.resetModal();

                if (this.isUpdate) {
                    this.getExecutiveDetails();
                    this.$set(this.payload, 'id', this.executive.id);
                } else {
                    this.$delete(this.payload, 'id');
                }
            },

            payload: {
                deep: true,
                handler() {
                    $('[name=executive_type_id]').val(this.payload.executive_type_id).change();
                    $('[name=gender]').val(this.payload.gender).change();
                    $('[name=educational_level_id]').val(this.payload.educational_level_id).change();
                }
            },

            constituencyId(value) {
                $('[name=constituency_id]').val(value).change();
            }
        },

        methods: {
            ...mapActions({
                loadBranches: 'Branches/all',
                loadConstituencies: 'Constituencies/all',
                loadExecutiveTypes: 'ExecutiveTypes/all',
                loadEducationalLevels: 'EducationalLevels/all'
            }),

            /**
			 * Trigger image upload by clicking on the hidden file field.
			 *
			 * @return {Undefined}
			 */
			initImageUpload() {
				const fileField = this.$refs.uploadField;

				if (fileField) {
					fileField.click();
				}
			},

            /**
			 * Set the value of the field to the uploaded image.
			 * Return false if no file has been selected.
			 *
			 * @param  {Object} event
			 * @return {Undefined}
			 */
			uploadImage(event) {
				let files = event.target.files;

				if (!files) {
					this.notify(this.buildErrors({
                        error: 'No or invalid file to be uploaded.'
                    }), 'error');

					return;
                }

                this.payload.photo = files[0];
				this.getImagePreview(event.target);
			},

			/**
			 * Get the preview of the uploaded image.
			 *
			 * @param  {Object} element
			 * @return {Undefined}
			 */
			getImagePreview(element) {
				getPreviewUrl(element)
					.then(url => this.previewImage = url)
					.catch(error => this.notify(this.buildErrors(error), 'error'));
            },

            /**
             * Reset the modal.
             *
             * @return {Undefined}
             */
            resetModal() {
                this.previewImage = '';
                this.payload.name = '';
                this.payload.photo = '';
            },

            /**
             * Get executive types
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getExecutiveTypes(query = {}) {
                this.loadExecutiveTypes(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Load constituencies
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getConstituencies(query = {}) {
                this.loadConstituencies(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Load branches
             *
             * @param {Object} query
             */
            getBranches(query = {}) {
                this.loadBranches(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'))
                    .then(() => {
                        this.$nextTick(() => {
                            $('[name=branch_id]').val(this.payload.branch_id).change();
                        });
                    });
            },

            /**
             * Load education levels
             *
             * @param {Object} query
             */
            getEducationalLevels(query = {}) {
                this.loadEducationalLevels(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Register usual JS event listeners
             */
            registerEventListeners() {
                $('[name=constituency_id]').on('change', this.constituencyChanged);
                $('[name=branch_id]').on('change', this.branchChanged);
                $('[name=executive_type_id]').on('change', this.executiveTypeChanged);
                $('[name=educational_level_id]').on('change', this.educationalLevelChanged);
                $('[name=gender]').on('change', this.genderChanged);
            },

            /**
             * Handler for when constituency is changed
             *
             * @param {Object} event
             */
            constituencyChanged(event) {
                this.constituencyId = event.target.value;
                this.getBranches({constituency_id: event.target.value});
            },

            /**
             * Handler for when branch is changed
             *
             * @param {Object} event
             */
            branchChanged(event) {
                this.payload.branch_id = event.target.value;
            },

            /**
             * Handler for when gender is changed
             *
             * @param {Object} event
             */
            genderChanged(event) {
                this.payload.gender = event.target.value;
            },

            /**
             * Handler for when educational level is changed
             *
             * @param {Object} event
             */
            educationalLevelChanged(event) {
                this.payload.educational_level_id = event.target.value;
            },

            /**
             * Handler for when executive type changes
             *
             * @param {Object} event
             */
            executiveTypeChanged(event) {
                this.payload.executive_type_id = event.target.value;
            },

            /**
             * Validate and emit event to save executive
             */
            saveExecutive() {
                this.$validator.validateAll()
                    .then((response) => {
                        if (response) {
                            this.$emit('save-executive', this.payload);
                        } else {
                            let messageConf = this.buildErrors(this.$validator.errors);

                            this.notify(messageConf, 'error');
                        }
                    });
            },

            /**
             * Fill payload with executive's data
             */
            getExecutiveDetails() {
                this.payload = {
                    ...this.executive
                };

                this.constituencyId = this.executive.branch.constituency.id;
            }
        },

        mounted() {
            let level = (this.level == 'regional') ? 'region' : this.level;

            this.loadConstituencies();
            this.loadExecutiveTypes({level});
            this.loadEducationalLevels({order_by: 'id'});
            this.registerEventListeners();
        }
    }
</script>

<style scoped>
    .executives-modal-body {
        max-height: 500px;
        overflow: auto;
    }

    input[type=file] {
        display: none;
    }

    img.img-thumbnail {
        height: 200px;
        max-width: 350px;
    }
</style>