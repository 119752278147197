<template>
    <modal
        :force="false"
        :show="show"
        :title="executive.name"
        :close-when-cancel="true"
        :showOkButton="false"
        :showCancelButton="false"
        @cancel="$emit('update:show', false)"
        @update:show="$emit('update:show', $event)">
        <div class="row justify-content-center" id="executives-info">
            <div class="col-xs-12 col-10">
                <div class="form-group text-center">
                    <img class="img-thumbnail" :src="executive.photo ? executive.photo : placeholder">
                    <br><br>
                </div>

                <table class="table">
                    <tbody>
                        <tr>
                            <td class="field">Name</td>
                            <td>{{ executive.name }}</td>
                        </tr>

                        <tr>
                            <td class="field">Gender</td>
                            <td>{{ executive.gender ? executive.gender : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Position</td>
                            <td>{{ executive.type ? getFullPosition(executive.type) : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Branch Code</td>
                            <td>{{ executive.branch && executive.branch.code ? executive.branch.code : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Branch</td>
                            <td>{{ executive.branch ? executive.branch.name : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Constituency</td>
                            <td>{{ (executive.branch && executive.branch.constituency) ? executive.branch.constituency.name : 'N/A'}}</td>
                        </tr>

                        <tr>
                            <td class="field">Region</td>
                            <td>{{ (executive.branch && executive.branch.constituency && executive.branch.constituency.region) ? executive.branch.constituency.region.name : 'N/A' }} </td>
                        </tr>

                        <tr>
                            <td class="field">Phone</td>
                            <td>{{ executive.phone }}</td>
                        </tr>

                        <tr>
                            <td class="field">Email</td>
                            <td>{{ executive.email ? executive.email : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Date Of Birth</td>
                            <td>{{ executive.date_of_birth ? executive.date_of_birth : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Educational Background</td>
                            <td>{{ executive.educational_level ? executive.educational_level.name : 'N/A' }}</td>
                        </tr>

                        <tr>
                            <td class="field">Added On</td>
                            <td>{{ executive.created_at | formattedDateTime }}</td>
                        </tr>

                        <tr>
                            <td class="field">Added By</td>
                            <td>{{ executive.added_by ? executive.added_by.name : 'N/A' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        executive: {
            type: Object,
            default: false
        }
    },

    methods: {
        /**
         * Get the full position of an executive type
         *
         * @param {Object} type Type of executive
         */
        getFullPosition(type) {
            let qualifier = type.hierarchy ? type.hierarchy.name : '';

            if (qualifier == 'Region') {
                qualifier = 'Regional';
            }

            return `${qualifier} ${type.name}`;
        },
    }
}
</script>

<style scoped>
    img.img-thumbnail {
        height: 200px;
        max-width: 400px;
    }

    .field {
        font-weight: bold;
    }

    #executives-info {
        height: 550px;
        overflow-y: scroll;
    }
</style>